.loadingComponent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    backdrop-filter: blur(2px); /* Blur effect */
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background */

}